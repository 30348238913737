import axios from "axios"
// 创建一个实例
const server = axios.create({
  baseURL: "",
  timeout: 6000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization':localStorage.getItem('token')//不能在这里加 初始化实例的时候还没有token  不是动态初始化的
  }
})
// 添加请求拦截器
server.interceptors.request.use(
  function (config) {
    if(localStorage.getItem('token')){
      config.headers['Authorization']='Bearer'+localStorage.getItem('token')
    }
    return config
  },
  function (error) {
    console.log(error)
    return Promise.reject(error)
  })

server.interceptors.response.use(function (response) {
  console.log(response)

  return response

},
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log(error)
    return Promise.reject(error)

  })
export default server
