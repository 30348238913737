import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import  "lib-flexible/flexible"
import "element-plus/theme-chalk/el-pagination.css";
import '@/assets/font/font.css'
// import moment from 'moment'



createApp(App).use(store).use(router).mount('#app')
// App.config.globalProperties.$moment = moment
