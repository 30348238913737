import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import PCHome from '../views/pcPage/Home.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'PCHome',
  //   redirect:"/PChome",
  //   component: PCHome
  // },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/newList',
    name: 'newList',
    component: ()=> import('../views/newList.vue')
  },
  {
    path: '/newDetail',
    name: 'newDetail',
    component: ()=> import('../views/newDetail.vue')
  },

  {
    path: '/',
    name: 'PCHome',
    component: ()=> import('../views/pcPage/Home.vue')
  },
  {
    path: '/pages/list/list',
    name: 'PcnewList',
    component: ()=> import('../views/pcPage/newList.vue')
  },
  {
    path: '/pages/detail/detail',
    name: 'PcnewDetail',
    component: ()=> import('../views/pcPage/newDetail.vue')
  },
  {
  path: '/PcnewProview',
  name: 'PcnewProview',
  component: ()=> import('../views/pcPage/proview.vue')
},
]
// 这里只是一个调用函数的过程  函数在声明的时候已经写好类型了  调用函数的时候是不用声明类型的 只有在函数声明的时候需要填写类型
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to,from,next)=>{
//   if(to.path=="login"){
//     next()
//   }else{
//     next()
//   }

// })

export default router
