import server from "./index"

// 获取栏目
export let getColumnList = (data) => {
  return server({
    url: "/api/official/getColumnList",
    method: "post",
    data: data
  })
}
// 获取友情链接
export let getLinkList = (data) => {
  return server({
    url: "/api/official/getLinkList",
    method: "post",
    data: data
  })
}
// 获取官网设置
export let getSettingList = (data) => {
  return server({
    url: "/api/official/getSettingList",
    method: "post",
    data: data
  })
}
// 获取列表分页
export let getArticleQuery = (data) => {
  return server({
    url: "/api/official/getArticleQuery",
    method: "post",
    data: data
  })
}
// 获取文章详情
export let getArticleDetail = (data) => {
  return server({
    url: "/api/official/getArticleDetail",
    method: "post",
    data: data
  })
}
// 更新文章点击量
export let updateArticle = (data) => {
  return server({
    url: "/api/official/updateArticle",
    method: "post",
    data: data
  })
}

// 获取首页轮播
export let getArticleCarousel = (data) => {
  return server({
    url: "/api/official/getArticleCarousel",
    method: "post",
    data: data
  })
}
// 获取文章详情预览
export let getArticleProview = (data) => {
  return server({
    url: "/api/official/getArticlePreviewDetail",
    method: "post",
    data: data
  })
}

